// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/news/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-news-markdown-remark-frontmatter-slug-jsx" */)
}

